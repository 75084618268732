import { CreateControllerFn, IWixAPI } from '@wix/yoshi-flow-editor';
import { extendControllerParams } from '../../lib/extendControllerParams';
import { SEARCH_LIGHTBOX_TPA_PAGE_ID } from '@wix/site-search-common';

const createController: CreateControllerFn = async (params) => {
  const extendedParams = await extendControllerParams(params);
  return {
    pageReady: async () => {
      const searchButtons = extendedParams.$w('@searchButton');
      if (searchButtons.length === 0) {
        return;
      }
      const { wixCodeApi } = extendedParams;
      const lightboxId = await getSearchModalLightboxId(wixCodeApi);

      searchButtons.onClick(async () => {
        if (!lightboxId) {
          // eslint-disable-next-line no-console
          console.error('Search modal lightbox not found');
        } else {
          await wixCodeApi.window.openLightboxById(lightboxId, {});
        }
      });
    },
  };
};

async function getSearchModalLightboxId(
  wixCodeApi: IWixAPI,
): Promise<string | undefined> {
  const siteStructure = await wixCodeApi.site.getSiteStructure({
    includePageId: true,
  });
  return siteStructure.lightboxes.find(
    ({ tpaPageId }) => tpaPageId === SEARCH_LIGHTBOX_TPA_PAGE_ID,
  )?.id;
}

export default createController;
